import React, { useLayoutEffect, useState } from "react";
import { Button } from "clutch/src/Button/Button.jsx";
import { useScreenSize } from "clutch/src/util/use-screen-size.mjs";
import { t } from "i18next";

import mainRefs from "@/__main__/refs.mjs";
import { APP_SCROLLER, CONTENT_HEADER_Z_INDEX } from "@/app/constants.mjs";
import {
  CONTENT_HEADER_ID,
  CONTENT_HEADER_MIDDLE_CLASS,
} from "@/app/ContentHeader.jsx";
import { Outer } from "@/app/ContentHeader.style.jsx";
import { HeaderNavDropdown } from "@/feature-reskin-probuilds-net/HeaderNavDropdown.jsx";
import {
  HeaderContainer,
  HeaderInner,
} from "@/feature-reskin-probuilds-net/HeaderWrapper.style.jsx";
import { useHeaderHeight } from "@/util/app-query.mjs";
import { INTERNAL_CONTAINER_ID } from "@/util/constants.mjs";
import { OBSERVE_CLASS } from "@/util/exit-transitions.mjs";
import globals from "@/util/global-whitelist.mjs";
import isMobile from "@/util/is-mobile.mjs";
import { proxy } from "@/util/proxy.mjs";
import { useRouteComponent } from "@/util/router-hooks.mjs";
import useCallbackRef from "@/util/use-callback-ref.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";
import { watchSticky } from "@/util/use-sticky.mjs";

const headerState = proxy({
  headerVisible: true,
  headerPadding: 0,
});

const ContentHeader = () => {
  const { headerVisible, headerPadding } = useSnapshot(headerState);

  const RouteComponent = useRouteComponent();
  const [titleNode, setTitleNode] = useState<HTMLElement>();

  const headerHeight = useHeaderHeight();

  const shouldShowHeaderBar = !headerVisible;

  useLayoutEffect(() => {
    const body = globals.document.querySelector(`body`);
    body.style.setProperty(
      "--secondary-nav-background-color",
      shouldShowHeaderBar ? `var(--shade9-75)` : "transparent",
    );
    body.style.setProperty(
      "--secondary-nav-border-color",
      shouldShowHeaderBar ? `var(--shade7)` : "transparent",
    );
  }, [shouldShowHeaderBar]);

  useLayoutEffect(() => {
    const t = setTimeout(() => {
      const [backdropEl] = globals.document.getElementsByClassName(
        "content-header-backdrop",
      );
      if (!(backdropEl instanceof HTMLElement)) return;
      const height = shouldShowHeaderBar ? headerPadding : 0;
      backdropEl.style.setProperty("--height", `${height}px`);
      if (shouldShowHeaderBar && headerPadding) {
        const root = globals.document.documentElement;
        root.style.setProperty("--page-tabs-height", `${height}px`);
      }
    });
    return () => clearTimeout(t);
  }, [headerPadding, shouldShowHeaderBar]);

  const windowSize = useScreenSize();
  const onDomReady = useCallbackRef(() => {
    const cleanup = [];

    const titleNode = globals.document.querySelector(
      // must select inside observe class because page header
      // may be in transition
      `.${OBSERVE_CLASS} .page-header`,
    );

    const header = globals.document.getElementById(CONTENT_HEADER_ID);

    // height at which the bottom of the nav buttons stop being interactable
    const headerClipHeight = header ? getHeaderClipHeight(header) : 0;

    const root = globals.document.getElementById(INTERNAL_CONTAINER_ID);

    // trigger a required re-render for deferred components, eg. page header conditionally on router isLoaded
    const titleObs = new MutationObserver(() => {
      const titleNode = root.querySelector(`.${OBSERVE_CLASS} .page-header`);
      if (titleNode) {
        setTitleNode(titleNode as HTMLElement);
        return titleObs.disconnect();
      }
    });
    titleObs.observe(globals.document.body, {
      childList: true,
      subtree: true,
    });
    cleanup.push(() => titleObs.disconnect());

    if (!titleNode) {
      const [scroller] = root.getElementsByClassName(APP_SCROLLER);

      headerState.headerVisible = true;
      headerState.headerPadding = headerHeight;

      let lastVisible = true;
      const scrollHandler = ({ target }) => {
        const visible = target.scrollTop < headerHeight - headerClipHeight;
        if (visible === lastVisible) return;

        lastVisible = visible;

        headerState.headerVisible = visible;
        headerState.headerPadding = visible ? 0 : headerHeight;
      };
      scroller.addEventListener("scroll", scrollHandler, { passive: true });
      cleanup.push(() => scroller.removeEventListener("scroll", scrollHandler));
      return () => cleanup.forEach((fn) => fn());
    }

    // must reset header to minimum to allow first callback to shrink
    headerState.headerPadding = 0;

    const run = (mutations?: MutationRecord[]) => {
      // due to bug in linkedom which fires callback on child attribute change,
      // need to do this check to prevent hang on integration test runner
      if (mutations)
        for (const { attributeName } of mutations)
          if (attributeName?.startsWith("data-")) return;

      cleanup.forEach((fn) => fn());
      cleanup.length = 0;
      let cumulativeOffset = 0;
      let cumulativeRootMargin = 0;

      (titleNode.parentElement?.childNodes ?? []).forEach((node) => {
        if (!(node instanceof HTMLElement)) return;
        const isSticky = getComputedStyle(node).position === "sticky";
        const zOffset = parseInt(node.dataset.stickyZ) || 0;
        if (isSticky) node.dataset.sticky = "true";
        if (isSticky || zOffset)
          node.style.zIndex = String(CONTENT_HEADER_Z_INDEX + 1 + zOffset);

        if (!(node.dataset.sticky || node === titleNode)) return;

        node.style.top = `${cumulativeOffset}px`;

        const options = {
          threshold: 1,
          rootMargin: `${
            -(cumulativeRootMargin || headerClipHeight) - 1
          }px 0px 0px 0px`,
          root,
        };
        const stickyCallback = (entry: IntersectionObserverEntry) => {
          // if the node was removed, do set pageHeaderStatus
          if (entry.rootBounds.height === 0) return;

          if (node === titleNode) {
            // skip an edge case where the node is not intersecting but is at the top.
            // this happens when a page is animating in and the page header is out of bounds
            // NOTE: adjusting root bounds does NOT fix this edge case.

            headerState.headerVisible =
              entry.isIntersecting ||
              entry.boundingClientRect.top === entry.intersectionRect.top;
          }

          headerState.headerPadding = resolveNewHeight(entry);

          // skip applying sticky attribute in the above edge case
          const skip =
            !entry.isIntersecting &&
            entry.boundingClientRect.top === entry.intersectionRect.top;

          return skip;
        };

        cleanup.push(watchSticky(node, options, stickyCallback));

        const dataHeight = node.dataset.stickyHeight;
        const height =
          typeof dataHeight === "string"
            ? parseInt(dataHeight)
            : node.offsetHeight;

        node.dataset.stickyCumulativeHeightPrev = cumulativeOffset.toString(10);
        cumulativeOffset += height;
        cumulativeRootMargin += height;
        node.dataset.stickyCumulativeHeight = cumulativeOffset.toString(10);
      });
    };

    const mutObs = new MutationObserver(run);
    if (titleNode?.parentElement) {
      mutObs.observe(titleNode.parentElement, {
        childList: true,
        subtree: true,
      });
    }

    run();

    return () => {
      mutObs.disconnect();
      cleanup.forEach((fn) => fn());
    };
  }, [titleNode, windowSize, RouteComponent, headerHeight]);

  return (
    <Outer id={CONTENT_HEADER_ID}>
      <div className="content-header-backdrop" ref={onDomReady} />
      <div
        className={CONTENT_HEADER_MIDDLE_CLASS}
        style={
          {
            "--center-padd": "var(--page-padding)",
          } as Record<string, string>
        }
      >
        <HeaderInner>
          <HeaderNavDropdown />
          <LeftNav />
          <RightNav />
        </HeaderInner>
      </div>
      <FloatingElements />
    </Outer>
  );
};

export function HeaderWrapper() {
  return (
    <HeaderContainer>
      <ContentHeader />
    </HeaderContainer>
  );
}

export function LeftNav() {
  return (
    <ul className="nav-items left">
      <li>
        <a href="/">{t("common:navigation.home", "Home")}</a>
      </li>
      <li>
        <a href="/champions">{t("probuildsnet:nav.champions", "Champions")}</a>
      </li>
      <li>
        <a href="/pros">{t("probuildsnet:nav.probuilds", "Pros")}</a>
      </li>
      {/* <li>
        <a href="/teams">{t("probuildsnet:nav.teams", "Teams")}</a>
      </li>
      <li>
        <a href="/feed">{t("probuildsnet:nav.liveFeed", "Live Feed")}</a>
      </li> */}
    </ul>
  );
}

export function RightNav() {
  return (
    <ul className="nav-items right">
      <li>
        <a href="/settings">{t("common:navigation.settings", "Settings")}</a>
      </li>
      {!isMobile() && (
        <li className="primary">
          <Button emphasis="high" href="https://blitz.gg/download">
            {t("championgg:nav.download", "Download Free Blitz App")}
          </Button>
        </li>
      )}
    </ul>
  );
}

const FloatingElements = () => {
  const { floatingElementsInner } = useSnapshot(mainRefs.components);

  return (
    <>
      {floatingElementsInner.map((Child, i) => (
        <Child key={Child.name + i} />
      ))}
    </>
  );
};

const resolveNewHeight = (entry) => {
  return entry.isIntersecting
    ? Math.min(
        headerState.headerPadding,
        entry.target.dataset.stickyCumulativeHeightPrev,
      )
    : Math.max(
        headerState.headerPadding,
        entry.target.dataset.stickyCumulativeHeight,
      );
};

const getHeaderClipHeight = (header: HTMLElement) => {
  const [headerMiddle] = header.getElementsByClassName(
    CONTENT_HEADER_MIDDLE_CLASS,
  );
  if (!headerMiddle) return 0;
  let largestChildHeight = 0;
  for (const child of headerMiddle.childNodes) {
    if (!(child instanceof HTMLElement)) continue;
    const height = child.offsetHeight + child.offsetTop;
    if (height > largestChildHeight) largestChildHeight = height;
  }
  const headerClipHeight = largestChildHeight;
  return headerClipHeight || 0;
};
