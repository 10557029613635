import React, { useRef } from "react";
import { Modal } from "clutch/src/Modal/Modal.jsx";
import { t } from "i18next";

import { appURLs, GAME_NAME_MAP, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import {
  dialogCssOverride,
  NavDropdown,
} from "@/feature-reskin-probuilds-net/HeaderNavDropdown.style.jsx";
import {
  LeftNav,
  RightNav,
} from "@/feature-reskin-probuilds-net/HeaderWrapper.jsx";
import refs from "@/feature-reskin-probuilds-net/refs.mjs";
import ChevronDown from "@/inline-assets/chevron-down.svg";
import { GameIcon } from "@/shared/GameFilterBar.jsx";
import { classNames } from "@/util/class-names.mjs";

function GameListItem(symbol) {
  return (
    <li key={GAME_SHORT_NAMES[symbol]}>
      <a href={`https://blitz.gg/${GAME_SHORT_NAMES[symbol]}`}>
        <div>{<GameIcon symbol={symbol} />}</div>
        <span>{t(...(GAME_NAME_MAP[symbol] as Translation))}</span>
      </a>
    </li>
  );
}

export function HeaderNavDropdown() {
  const modalRef = useRef<HTMLDialogElement>();

  function handleToggleModal() {
    if (modalRef.current.open) {
      modalRef.current.close();
      return;
    }
    modalRef.current.showModal();
  }

  return (
    <NavDropdown>
      <div {...classNames("nav-dropdown-trigger")}>
        <a href="/" style={{ marginRight: "0.5rem" }}>
          <img
            src={`${appURLs.CDN}/probuildsnet/logo.webp`}
            alt="ProBuilds.net"
            width="147"
            height="20"
          />
        </a>
        <div onClick={handleToggleModal} style={{ padding: "0.5rem" }}>
          <ChevronDown width="20" height="20" />
        </div>
      </div>
      <Modal className={dialogCssOverride()} ref={modalRef}>
        <ul className="nav-dropdown">
          <li className="desktop-only">
            <a href="/">
              <div>
                <img src={`${appURLs.CDN}/championgg/img/pb.png`} />
              </div>
              <span>{t("championgg:nav.probuilds", "Probuilds")}</span>
            </a>
          </li>
          <LeftNav />
          <RightNav />
          <li className="divider" />
          <li className="section-title">
            {t("championgg:nav.blitz", "Blitz")}
          </li>
          {Array.from(refs.activeGameSet).map((symbol) => GameListItem(symbol))}
        </ul>
      </Modal>
    </NavDropdown>
  );
}
