import { styled } from "goober";
import { mobile, screenNot } from "clutch/src/Style/style.mjs";

import { CONTENT_HEADER_Z_INDEX } from "@/app/constants.mjs";

const AD_WIDTH = 300;

export const HeaderContainer = styled("nav")`
  position: sticky;
  top: 0;
  z-index: ${CONTENT_HEADER_Z_INDEX};
  --page-padding: var(--sp-2);

  ${screenNot(mobile)} {
    .content-header-middle {
      max-width: calc(
        var(--sp-container) + var(--page-padding) + ${AD_WIDTH}px
      ) !important;
    }
  }
`;

export const HeaderInner = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 1;

  .nav-items {
    display: flex;

    &.right {
      margin-left: auto;
    }

    .primary {
      display: flex;
      align-items: center;

      a {
        display: inline-flex;
        align-items: center;
      }
    }

    a {
      display: inline-block;
      padding: var(--sp-2) var(--sp-4);
    }
  }

  @media (max-width: 950px) {
    .nav-items.left {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .nav-items {
      display: none;
    }
  }
`;
